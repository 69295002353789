var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.hasUnansweredDialogs &&
        !_vm.userInfo.disableHint &&
        _vm.$route.name === 'newDialog'
    )?_c('NewMessageTip'):_vm._e(),_c('el-form',{ref:"newForm",staticClass:"e-dialog__new",attrs:{"model":_vm.editMessage,"rules":_vm.rules}},[(_vm.loading || _vm.loadingDone)?_c('div',{staticClass:"e-dialog__loading"},[(_vm.loading)?_c('loading'):_vm._e()],1):_vm._e(),_c('el-input',{staticClass:"e-dialog__new-subject",attrs:{"placeholder":((_vm.$t('system.subject')) + "...")},model:{value:(_vm.editMessage.subject),callback:function ($$v) {_vm.$set(_vm.editMessage, "subject", $$v)},expression:"editMessage.subject"}}),_c('div',{staticClass:"e-dialog__answer-field"},[_c('TiptapEditor',{key:_vm.editMessage ? _vm.editMessage.id : 1,ref:"messageEditor",staticClass:"message-editor-tiptap",attrs:{"placeholder":_vm.$t('system.writeAMessage'),"actions":_vm.editorOptions},on:{"change":_vm.debouncedAutosave},model:{value:(_vm.editMessage.body),callback:function ($$v) {_vm.$set(_vm.editMessage, "body", $$v)},expression:"editMessage.body"}}),_c('div',{staticClass:"e-dialog__charcount",class:{
          'e-dialog__charcount--alert': _vm.maxCharsExceeded
        }},[_vm._v(" "+_vm._s(_vm.charCountString)+" ")]),_c('transition',{attrs:{"name":"fade-up"}},[(_vm.autoSaved && !_vm.autoSaving)?_c('div',{staticClass:"e-dialog__autosave"},[_c('IconCheck'),_c('span',[_vm._v(_vm._s(_vm.$t('system.draft.autosaved')))])],1):_vm._e()]),_c('transition',{attrs:{"name":"fade-up"}},[(_vm.autoSaving)?_c('div',{staticClass:"e-dialog__autosave"},[_c('i',{staticClass:"el-icon-loading"}),_c('span',[_vm._v(_vm._s(_vm.$t('system.draft.autosaving')))])]):_vm._e()])],1),_c('footer',{staticClass:"e-dialog__footer"},[_c('el-button',{staticClass:"e-btn--delete e-btn--ghost",on:{"click":_vm.closeForm}},[_c('IconClose'),_c('span',{staticClass:"e-mobile-hidden"},[_vm._v(" "+_vm._s(_vm.$t('system.cancel'))+" ")])],1),(_vm.editMessage.id)?_c('el-button',{staticClass:"e-btn--delete e-btn--ghost",on:{"click":_vm.deleteItem}},[_c('IconTrash'),_c('span',{staticClass:"e-mobile-hidden"},[_vm._v(" "+_vm._s(_vm.$t('system.delete'))+" ")])],1):_vm._e(),_c('el-tooltip',[_c('div',{attrs:{"slot":"content"},domProps:{"innerHTML":_vm._s(_vm.submitTooltip)},slot:"content"}),_c('div',{staticClass:"e-btn--send"},[_c('el-button',{attrs:{"disabled":_vm.autoSaving ||
                _vm.editMessage.subject.length === 0 ||
                _vm.editMessage.body.length === 0,"type":"tertiary"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm('newForm')}}},[_vm._v(" "+_vm._s(_vm.$t('system.sendMessage'))+" "),_c('IconSend')],1)],1)])],1)],1),(_vm.currentDialog)?_c('AbsencePrompt',{attrs:{"confirm-is-open":_vm.absenceDialogOpen,"absent-until":_vm.currentDialog.absentDate,"forward-loading":_vm.absenceForwardLoading,"wait-loading":_vm.absenceWaitLoading},on:{"close-dialog":function($event){_vm.absenceDialogOpen = false},"close-absent-dialog":_vm.closeAbsentDialog}}):_vm._e(),(_vm.currentDialog)?_c('DeletedPrompt',{attrs:{"show":_vm.deletedPromptOpen},on:{"close-dialog":_vm.closeDeletedPrompt,"forward-dialog":_vm.closeDeletedPrompt}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }