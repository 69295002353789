<template>
  <div v-if="alertVisible" role="alert" class="el-alert el-alert--warning">
    <i class="el-alert__icon el-icon-warning is-big" />
    <div class="el-alert__content">
      <span class="el-alert__title is-bold">
        {{ $t('messages.dialog.new.hintTitle') }}
      </span>
      <div class="el-alert__description">
        <p v-html="$t('messages.dialog.new.hintBody')" />
        <el-checkbox v-model="disablePermanent">
          {{ $t('messages.dialog.new.hintDisablePermanent') }}
        </el-checkbox>
      </div>
    </div>
    <i v-if="closeLoading" class="el-icon-loading el-alert__closebtn" />
    <i v-else class="el-alert__closebtn el-icon-close" @click="closeAlert" />
  </div>
</template>

<script>
import api from 'api'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'NewMessageTip',

  data: () => ({
    alertVisible: true,
    disablePermanent: false,
    closeLoading: false
  }),

  computed: mapGetters(['userInfo']),

  methods: {
    ...mapActions(['updateUserInfo']),

    async closeAlert() {
      if (this.disablePermanent) {
        this.closeLoading = true

        try {
          await api.call('disableHint')
          this.updateUserInfo({
            id: this.userInfo.id,
            data: {
              disableHint: true
            }
          })
          this.alertVisible = false
        } catch (error) {
          this.$error(error)

          if (error.messages) {
            this.$message.error(this.$t(error.messages[0]))
          }
        } finally {
          this.closeLoading = false
        }
      } else {
        this.alertVisible = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_assets/newmessagetip';
</style>
